// @flow
import React from "react"
import "../../styles/radio.css"

type RadioProps = {
  id?: string,
  name: string,
  value: string,
  label?: string,
  checked?: Boolean,
  handleOnChange?: () => void | Promise<void>,
  classNames?: Object,
  dataCy?: string,
  innerRef: () => void | Promise<void>,
  disabled?: boolean,
}

const Radio = ({
  id = "",
  name,
  value,
  label = "",
  checked,
  handleOnChange = () => {},
  classNames = {},
  dataCy = "",
  innerRef = () => {},
  disabled,
}: RadioProps) => (
  <label className={`${classNames.wrapper}${disabled ? " opacity-50" : ""}`}>
    {label}
    <input
      ref={innerRef}
      id={id}
      type="radio"
      name={name}
      value={value}
      onChange={handleOnChange}
      checked={checked}
      className={classNames.input ? classNames.input : ""}
      data-cy={dataCy}
      disabled={disabled}
    />
    <span className={classNames.checkmark ? classNames.checkmark : ""} />
  </label>
)

export default React.memo<RadioProps>(Radio)
