import { useCallback, useContext, useEffect, useState } from "react";
import { activationCodeProducts } from "../../constants/forms";
import messages from "../../constants/messages";
import { generateActivationCodes } from "../../graphql/generateActivationCodes";
import { searchResellerAdminByAccessId } from "../../graphql/searchResellerByAccessId";
import { GlobalContext } from "../../pages/Dashboard";
import { debounce, publicAPI } from "../../utils/utilities";
import { FormValidator, PATTERNS } from "../../utils/validator";
import Alert from "../Alert";
import Autocomplete from "../Autocomplete";
import Button from "../Button";
import LoadingIndicator from "../LoadingIndicator";
import MultipleSelect from "../MultipleSelect";
import Textbox from "../Textbox";

const MAX_NUM_ACTIVATION_CODES = 10000;
const MAX_DAYS_IN_YEAR = 365;

type ApiResponse = {
  message?: string;
  // This is because Alert contains a mistake
  type?: any;
};

type ErrorsType = {
  partnerId?: string;
  productHandle?: string;
  expiryInDays?: string;
  activationUrl?: string;
  total?: string;
};

type GenActivationCodeInput = {
  total?: number;
  partnerId?: { id: string; accessId: string };
  productHandle?: string;
  expiryInDays?: number;
  activationUrl?: string;
};

const ActivationCodes = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse>({});
  const [errors, setErrors] = useState<ErrorsType>({});
  const [genActivationCodeInput, setGenActivationCodeInput] =
    useState<GenActivationCodeInput>({});

  const [isExpandAccessId, setIsExpandAccessId] = useState(false);
  const [keySearchAccessId, setKeySearchAccessId] = useState("");
  const [existingReseller, setExistingReseller] = useState<any[]>([]);
  const [isAccessIdSelected, setIsAccessIdSelected] = useState<boolean>(false);

  const [setUnauthorized] = useContext(GlobalContext);

  useEffect(() => {
    handleResetForm();
  }, []);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setErrors({
      ...errors,
      [fieldName]: "",
    });

    setGenActivationCodeInput({
      ...genActivationCodeInput,
      [fieldName]: e.target.value,
    });
  };

  const handleResetForm = () => {
    setErrors({});
    setGenActivationCodeInput({
      total: 1,
      partnerId: { id: "", accessId: "" },
      productHandle: "",
      expiryInDays: 365,
      activationUrl: process.env.REACT_APP_DEFAULT_ACTIVATION_URL,
    });
    setIsAccessIdSelected(false);
  };

  const handleErrors = () => {
    const invalidError = "Invalid input";

    const { partnerId, total, productHandle, expiryInDays, activationUrl } =
      genActivationCodeInput;

    const errs = {
      partnerId: FormValidator(
        ["REQUIRED"],
        `${partnerId}`.trim(),
        "Partner Id",
        invalidError
      ),
      total: FormValidator(["REQUIRED"], total, "total", invalidError),
      productHandle: FormValidator(
        ["REQUIRED"],
        `${productHandle}`.trim(),
        "product handle",
        invalidError
      ),
      expiryInDays: FormValidator(
        ["REQUIRED"],
        expiryInDays,
        "expiry",
        invalidError
      ),
      activationUrl: FormValidator(
        ["REQUIRED"],
        `${activationUrl}`.trim(),
        "activation url",
        invalidError
      ),
    };

    if (total && !errs.total) {
      if (total < 1 || total > MAX_NUM_ACTIVATION_CODES) {
        errs.total = `Total can not be less than 1 or greater than ${MAX_NUM_ACTIVATION_CODES}`;
      }
    }

    if (expiryInDays && !errs.expiryInDays) {
      if (expiryInDays < 1 || expiryInDays > MAX_DAYS_IN_YEAR) {
        errs.expiryInDays = `Expiry can not be less than 1 or greater than ${MAX_DAYS_IN_YEAR}`;
      }
    }

    setErrors(errs);
    return errs;
  };

  const handleSubmit = () => {
    setIsProcessing(true);

    const input = {
      ...genActivationCodeInput,
      partnerId: genActivationCodeInput.partnerId?.accessId,
    };

    publicAPI(
      generateActivationCodes,
      ({ data }) => {
        if (data.errors) {
          setApiResponse({
            message: "Something went wrong",
            type: "error",
          });
          return;
        }

        const s3Attributes = data.generateActivationCodes.s3;

        // Getting uploaded file name from file path
        const filePathSplit = s3Attributes.filePath.split("/");
        // file name from S3 (example: 2022-09-20T23:32:11.347Z.csv)
        const fileName = filePathSplit[filePathSplit.length - 1];

        const element = document.createElement("a");
        const file = new Blob([s3Attributes.fileContent]);
        element.href = URL.createObjectURL(file);
        element.download = `${genActivationCodeInput.partnerId?.accessId}-${genActivationCodeInput.productHandle}-${fileName}`;
        document.body.appendChild(element);
        element.click();

        setApiResponse({
          type: "success",
          message: "Activation codes generated successfully",
        });

        setIsProcessing(false);
      },
      undefined,
      { input },
      () => setUnauthorized(true)
    );
  };

  const debouncedSearch = useCallback(
    debounce(
      (newValue, request, searchField) => request(newValue, searchField),
      500
    ),
    []
  );

  const handleSelectReseller = (item) => {
    const inputEvent = { target: { value: item } };
    handleOnChange(inputEvent, "partnerId");

    setIsExpandAccessId(false);
    setIsAccessIdSelected(true);
    setKeySearchAccessId(item.accessId);
  };

  const onOutFocusAccessId = (e) => {
    const key = e.target.value && e.target.value.trim();

    if (key) {
      if (!PATTERNS.ACCESS_ID.test(key)) {
        setErrors({
          ...errors,
          partnerId: messages.INVALID_RESELLER_ACCESS_ID,
        });

        return;
      }
    }
  };

  const handleSearchResellerByAccessId = async (key) => {
    setIsAccessIdSelected(false);
  
    if (!key || key === null || key === '') {
      return;
    }
  
    setIsProcessing(true);
  
    const inputEvent = { target: { value: key } };
    handleOnChange(inputEvent, 'partnerId');
  
    let nextToken;
    let resellers: any[] = [];
  
    do {
      await publicAPI(
        searchResellerAdminByAccessId,
        ({ data }) => {
          const resellersData = data?.searchResellerByAccessId?.data;
          nextToken = data?.searchResellerByAccessId?.lastEvaluatedKey;
          resellers = resellers.concat(resellersData);
        },
        undefined,
        { accessId: key, lastEvaluatedKey: nextToken },
        () => setUnauthorized(true)
      );
    } while (nextToken);
    setExistingReseller(resellers);
    setIsProcessing(false);
  };

  return (
    <>
      {isProcessing && <LoadingIndicator />}

      <Alert
        message={apiResponse.message}
        showAlert={!!apiResponse.message}
        onHideAlert={() => setApiResponse({})}
        type={apiResponse.type}
        showCloseIcon={apiResponse.type === "error"}
      />

      <p className="font-base-black text-gray-800 leading-tight mb-35 mt-35">
        Activation Codes
      </p>

      {/* Form */}
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Partner Id <span className="text-error">*</span>:
        </p>
        <Autocomplete
          error={errors.partnerId}
          toggleDropdown={setIsExpandAccessId}
          isExpanded={isExpandAccessId}
          onChange={(e) => {
            setErrors({
              ...errors,
              partnerId: "",
            });

            const keyEvent = e.target.value;
            setKeySearchAccessId(keyEvent);
            debouncedSearch(keyEvent, handleSearchResellerByAccessId);
          }}
          onSelect={(item) => handleSelectReseller(item, "partnerId")}
          results={existingReseller}
          keySearch={keySearchAccessId}
          // setErrors={handleErrors}
          // selectedOption={currentForm}
          handleOnFocus={() => setIsExpandAccessId(true)}
          renderListItem={(item) => {
            return <p className="text-base font-base-light">{item.accessId}</p>;
          }}
          handleOnBlur={onOutFocusAccessId}
        />
      </div>

      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Product Handle <span className="text-error">*</span>:
        </p>
        <MultipleSelect
          options={activationCodeProducts}
          setValue={({ value }) => {
            setErrors({
              ...errors,
              productHandle: "",
            });

            setGenActivationCodeInput({
              ...genActivationCodeInput,
              productHandle: value,
            });
          }}
          renderListItem={(item: any) => (
            <p className="text-base font-base-light">{item.name}</p>
          )}
          error={errors.productHandle}
          value={genActivationCodeInput?.productHandle}
          disabled={!isAccessIdSelected}
        />
      </div>

      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Expiry (in days) <span className="text-error">*</span>:
        </p>
        <Textbox
          value={`${genActivationCodeInput.expiryInDays}`}
          handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange(e, "expiryInDays")
          }
          classNames={{
            wrapper: "w-textbox-lg mb-32",
            textbox: "h-textbox-lg truncate w-textbox-lg pl-18",
            label: "text-2lg",
          }}
          type="number"
          error={errors.expiryInDays}
          disabled={!isAccessIdSelected}
        />
      </div>

      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Activation Url <span className="text-error">*</span>:
        </p>
        <Textbox
          value={genActivationCodeInput.activationUrl}
          handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange(e, "activationUrl")
          }
          classNames={{
            wrapper: "w-textbox-lg mb-32",
            textbox: "h-textbox-lg truncate w-textbox-lg pl-18",
            label: "text-2lg",
          }}
          error={errors.activationUrl}
          disabled={!isAccessIdSelected}
        />
      </div>

      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Total <span className="text-error">*</span>:
        </p>
        <Textbox
          value={`${genActivationCodeInput.total}`}
          handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange(e, "total")
          }
          type="number"
          classNames={{
            wrapper: "w-textbox-lg mb-32",
            textbox: "h-textbox-lg truncate w-textbox-lg pl-18",
            label: "text-2lg",
          }}
          error={errors.total}
          disabled={!isAccessIdSelected}
        />
      </div>

      <div className="flex justify-end">
        <Button
          classNames=" btn btn-primary w-btn-xs mb-30 mr-30 mt-30"
          type="submit"
          handleOnClick={() => {
            const error = handleErrors();
            if (
              Object.keys(error).some((key) => error[key as keyof ErrorsType])
            ) {
              return;
            }
            handleSubmit();
          }}
          disabled={isProcessing || !isAccessIdSelected}
        >
          Generate
        </Button>
        <Button
          classNames=" btn btn-primary w-btn-xs mb-30 mt-30"
          type="submit"
          handleOnClick={handleResetForm}
          disabled={isProcessing}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ActivationCodes;
