export const getUserByEmail = `
  query getUserByEmail($email: String!){
    getUserByEmail(email: $email) {
      ... on UserItemResponse {
        user {
          email
          givenName
          familyName
          addressStreet
          addressStreet2
          addressPostalCode
          addressLocality
          addressRegion
          addressStateCode
          addressCountry
          isReseller
        }
      }
    }
  }
`