import React, { useRef, useState, useCallback, useEffect } from 'react'
// AWS Amplify
import Amplify, { Auth } from "aws-amplify"
import Button from "../components/Button"
import Textbox from "../components/Textbox"
import PasswordTextbox from "../components/PasswordTextbox"
import { cognitoConfig } from '../utils/utilities'
import { FormValidator } from '../utils/validator'
import blackLogo from '../assets/icons/black-logo.svg'
import { useHistory } from 'react-router-dom'

import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import ReCAPTCHA from "react-google-recaptcha"
import { handleClickOutSideRecaptcha, getAutomationRecaptcha, ERRORS } from '../utils/recaptcha'
import { checkIsAdmin } from '../utils/utilities'

const Login = () => {
  const [isLogging, setIsLogging] = useState(false)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const [errors, setErrors] = useState({})
  let reCaptchaRef = useRef()
  let history = useHistory()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const recaptchaAutomation = getAutomationRecaptcha()

  useEffect(() => {
    Amplify.configure(cognitoConfig)
    Auth.currentSession()
      .then(() => {
        history.push('/admin')
      })
      .catch(() => {})
  }, [])

  const handleLoginError = (err) => {
    setIsLogging(false)
    setErrors({
      password: ERRORS[err.code] || err.message
    })
  }

  const handleVerifyRecaptchaV2 = async (email, password) => {
    if (reCaptchaRef) {
      const tokenV2 = await reCaptchaRef?.current?.executeAsync()
      reCaptchaRef?.current?.reset()
      handleLogin(email, password, tokenV2, true)
    }
    return
  }

  const handleLogin = (email, password, token, isRecaptchaV2 = false) => {
    let signInRequestParams = {
      username: email,
      password: password,
    }
    const validationDataName = isRecaptchaV2
      ? "recaptchaToken"
      : "recaptchaTokenV3"


    if (!recaptchaAutomation) {
      signInRequestParams.validationData = {
        Name: validationDataName,
        Value: token,
      }
    } else {
      signInRequestParams.validationData = {
        Name: validationDataName,
        Value: "",
        auth: recaptchaAutomation.auth,
        nonce: recaptchaAutomation.nonce,
      }
    }

    Auth.signIn(signInRequestParams)
      .then((user) => {
        setIsLogging(false)

        if(checkIsAdmin(user?.signInUserSession)) {
          history.push('/')

          return
        }

        setErrors({
          noPermission: ERRORS.NoPermission
        })

      }).catch(err => {
        if (
          err.message.toLowerCase().includes(ERRORS.FailedRecaptchaV3)
        ) {
          // Failed recaptcha v3
          handleVerifyRecaptchaV2(email, password)
        } else {
          handleLoginError(err)
        }
      })
  }

  const verifyRecaptchaV3 = useCallback(async (email, password) => {
    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha()

    handleLogin(email, password, token)
  }, [executeRecaptcha])

  const onClickOutsideRecaptcha = () => {
    setIsLogging(false)
  }

  const handleSubmit = () => {
    const email = emailRef?.current?.value?.trim().toLowerCase()
    const password = passwordRef?.current?.value

    const errors = {
      email: FormValidator(["REQUIRED", "EMAIL"], email, 'Email', ERRORS.IncorrectEmail),
      password: FormValidator(["REQUIRED", "PASSWORD"], password, 'Password', ERRORS.IncorrectPassword),
    }

    setErrors(errors)

    if (!Object.keys(errors).some(key => errors[key])) {
      setIsLogging(true)
      if (!recaptchaAutomation) {
        verifyRecaptchaV3(email, password)
        handleClickOutSideRecaptcha(onClickOutsideRecaptcha)
      } else {
        handleLogin(email, password)
      }
    }
  }

  const handleOnKeyPress = e => {
    if (e.which === 13) {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className="flex items-center justify-center w-full h-100vh">
      <form
        className="bg-white w-form-lg pt-53 md:pt-0 h-auth-form-lg text-center"
        autoComplete="off"
      >
        <img
          src={blackLogo}
          className="w-logo-lmod-lg h-logo-lmod-lg m-auto mt-74"
          alt="lesmills logo"
        />
        {errors.noPermission && <p className="font-base-light text-center text-error mt-30">{errors.noPermission}</p>}
        <Textbox
          label="Email"
          classNames={{
            textbox:
              "truncate pl-18 w-textbox-base h-textbox-base md:w-textbox-lg md:h-textbox-lg md:text-2lg",
            label: "md:text-2lg",
            wrapper: "md:mt-51 mt-44 mx-auto w-textbox-base md:w-textbox-lg",
          }}
          inputRef={emailRef}
          defaultValue=""
          error={errors.email}
          handleOnKeyPress={handleOnKeyPress}
          id="email"
          disabled={isLogging}
        />
        <PasswordTextbox
          classNames={{
            wrapper: "password-textbox md:mt-51 mt-44 mx-auto w-textbox-base md:w-textbox-lg",
          }}
          textbox={{
            handleOnKeyPress,
            label: 'Password',
            inputRef: passwordRef,
            defaultValue: "",
            error: errors.password,
            id: "password",
            disabled: isLogging,
          }}
        />
        {!recaptchaAutomation && (
          <ReCAPTCHA
            ref={reCaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_SITE_KEY}
          />
        )}
        <Button
          handleOnClick={handleSubmit}
          classNames="mx-auto btn-sign-in btn btn-primary pt-3 pb-3 w-btn-md mt-43 mb-38"
          disabled={isLogging}
          submitting={isLogging}
        >
          Sign in
        </Button>
      </form>
    </div>

  )
}

export default Login