import React, { useState, useEffect } from "react"
import { verifyCaptchaSignature } from "../graphql/verifyCaptchaSignature"

import { getParamsFromURL, setLocalStorage, AUTOMATION_RECAPTCHA } from "../utils/utilities"
import { useHistory } from "react-router-dom"

import Amplify, { API, graphqlOperation } from "aws-amplify"

Amplify.configure({
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT + "/public",
  },
})

const DisableCaptcha = () => {
  const [error, setError] = useState(undefined)
  let history = useHistory()

  useEffect(() => {
    const auth = getParamsFromURL("auth")
    const nonce = getParamsFromURL("nonce")
    API.graphql(graphqlOperation(verifyCaptchaSignature, {input: {
      auth: auth,
      nonce: nonce,
    }})).then(async ({ data }) => {
      if (data.verifyCaptchaSignature) {
              await setLocalStorage(AUTOMATION_RECAPTCHA, {
                auth: auth,
                nonce: nonce,
              })
              history.push('/login')
            } else {
              setError("Verify captcha signature failed")
            }
    }).catch(err => {
      setError(err)
    })
  }, [])

  return (
    <>{error && <p className="m-auto">Verify captcha signature failed</p>}</>
  )
}

export default DisableCaptcha
