import React, { useEffect, useState, useRef } from "react"
import { filterArrayOptions } from "../../utils/utilities"
import Spinner from "../LoadingIndicator/Spinner"
import Notification from "../Notification"
import SelectedOption from "../SelectedOption"

interface Props {
  options?: Array<any>,
  error?: string,
  selectedOptions?: Array<any>,
  label: string,
  setSelectedOptions: (arr: Array<any>) => void,
  // setErrors: () => void,
  renderListItem: (item?: any) => JSX.Element,
  isMultiSelect?: boolean,
  setValue?: (item?: any) => JSX.Element,
  disabled?: boolean,
  value?: string,
  loading?: boolean,
}

const MultipleSelect = ({
  options = [],
  error,
  label,
  selectedOptions = [],
  setSelectedOptions,
  // setErrors,
  renderListItem,
  isMultiSelect,
  setValue,
  disabled,
  value,
  loading,
}: Props) => {
  const [results, setResults] = useState(options)
  const [activeOption, setActiveOption] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const [searchKey, setSearchKey] = useState("")
  const selectRef = useRef(null)

  useEffect(() => {
    setResults(options)
  }, [options])

  React.useEffect(() => {
    const handleOutsideClick = (event: TouchEvent | MouseEvent) => {
      const mouseElement = event.target as Node
      if (selectRef.current && selectRef.current.contains(mouseElement)) {
        if(results.length && !disabled) {
          setIsExpanded(true)
        } 
        return
      }

      // if(!selectedOptions.length) {
      //   setErrors()
      // }
      setIsExpanded(false)
    }
  
    document.addEventListener("mousedown", handleOutsideClick, false)
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick, false)
    }
  }, [results, setIsExpanded, selectedOptions])

  useEffect(() => {
    setSearchKey(value)
  }, [value])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let key = e.target.value
    setSearchKey(key)
    setIsExpanded(true)

    if(!!key) {
      setResults(options.filter(item => {
        key = key.toLowerCase()
        return item.name.toLowerCase().includes(key) || item.id.toLowerCase().includes(key)
      }))
    } else {
      setResults(options)
    }
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleSelectOption(results[activeOption])
    } else if(e.keyCode === 40) { // Press down arrow
      if (activeOption === results.length - 1) {
        return
      }
      setActiveOption(activeOption + 1)
    } else if (e.keyCode === 38) { // Press up arrow
      if (activeOption === 0) {
        return
      }
      setActiveOption(activeOption - 1)
    }
  }

  const handleRemoveSelected = (id: string) => {
    const newList = selectedOptions.filter(item => item.id !== id)

    setSelectedOptions(newList)
    setIsExpanded(false)
  }

  const handleSelectOption = (item: any) => {
    if (!results.length) {
      return
    }

    if (isMultiSelect) {
      selectedOptions.push(item)
      setSelectedOptions(selectedOptions)
      setSearchKey("")

    } else {
      setValue(item)
      setSearchKey(item.name)
    }
  
    setIsExpanded(false)
  }

  const availableOptions = filterArrayOptions(results, selectedOptions)

  return (
    <div className={`mb-32 relative pb-5 w-textbox-lg${disabled ? " opacity-50" : ""}`} ref={selectRef}>
      {loading && <Spinner />}
      {!searchKey && !isExpanded && <span className="absolute top-10 left-10 z-99 text-gray-500">Please select...</span>}
      <div className={`flex flex-wrap w-full items-baseline border border-gray-500 min-h-50 relative pt-10 pl-10${!!error ? " border-red-600" : ""}`}>
        {isMultiSelect ? selectedOptions.map(item => (
          <SelectedOption
            key={item.id}
            name={item.name}
            handleRemove={() => handleRemoveSelected(item.id)}
            className="text-xs p-5 mr-5"
          />
        )): null}
        <input
          className={`pl-4 text-gray-800 font-base-light focus:outline-none${!isMultiSelect ? " w-full" : ""}`}
          onChange={e => {
            setActiveOption(0)
            handleOnChange(e)
          }}
          onKeyDown={handleOnKeyDown}
          value={searchKey}
          disabled={disabled}
          onBlur={() => {
            if(!!value && !isMultiSelect) {
              setSearchKey(value)
            }
          }}
          autoComplete="off"
        />
        <label
          className="text-2lg top-x-10 left-10 bg-white px-2 font-base-light text-gray-500 absolute leading-none"
        >
          {label}
        </label>
      </div>
      {(availableOptions.length && isExpanded) ? (
        <ul
          className={`absolute w-full border-r border-l border-b border-gray-500 bg-white z-100 max-h-270 overflow-auto`}
        >
          {availableOptions.map((item: any, index) => {
            return (
              <li
                key={item.id}
                onClick={(e) => {
                  handleSelectOption(item)
                  setActiveOption(0)
                }}
                onMouseOver={(e) => {
                  e.preventDefault()
                  setActiveOption(index)
                }}
                className={`py-15 pl-10 cursor-pointer ${activeOption === index ? " bg-gray-100" : ""}`}
              >
                {renderListItem(item)}
              </li>
            )
          })}
        </ul>
      ) : null}
      {(!!searchKey && !results.length && isExpanded) ? (
        <p
          className="text-gray-400 font-base-light text-3xs py-15 pl-10 hover:bg-gray-100 cursor-pointer border-r border-l border-b border-gray-500 w-full absolute bg-white z-100"
          data-cy="not_found_address_label"
        >
          Not found
        </p>
      ) : null}
      {error && <Notification type="error" message={error} />}
    </div>
  )
}

export default MultipleSelect