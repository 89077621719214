import ISO6391 from "iso-639-1"

import moment from "moment"

export const PATTERNS = {
  EMAIL: /^(\w+[\w-+\\.]*)@(\w+([-\\.]\w+)*\.[a-zA-Z]{2,})$/,
  PASSWORD: /^.{7,}$/,
  ACCESS_ID: /^\S+$/,
}

export const FormValidator = (types, value, fieldName, invalidError) => {
    let errorMsg = ""
    const noOfTypes = types.length
  
    for (let index = 0; index < noOfTypes; index++) {
      const type = types[index]
      const validation = PATTERNS[type]
  
      switch (type) {
        case "REQUIRED":
          if (!value || value.length === 0) {
            errorMsg = `${fieldName} is required`
          }
          break
  
        case "LANG_CODE":
          if (!ISO6391.validate(value.toLowerCase())) {
            errorMsg = "Please enter a valid language code"
          }
          break

        case "EMAIL":
        case "PASSWORD":
        case "ACCESS_ID":
          if (value && validation && !validation.test(value)) {
            errorMsg = invalidError
          }
          break
        
        case "DATES":
          const end_date = moment(value.end_date)
          const start_date = moment(value.start_date)

          if(end_date.isBefore(start_date)) {
            errorMsg = invalidError
          }
          break
  
        default:
          break
      }
      if (errorMsg) {
        return errorMsg
      }
    }
    return errorMsg
  }