export const searchResellerAdminByAccessId = `
  query searchResellerByAccessId($accessId: String, $lastEvaluatedKey: String){
    searchResellerByAccessId(accessId: $accessId, lastEvaluatedKey: $lastEvaluatedKey) {
      ... on ResellerAdminList {
        __typename
        lastEvaluatedKey
        data {
          id
          accessId
          name
        }
      }
    }
  }
`