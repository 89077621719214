export const queryReseller = `
  query queryReseller($accessId: String!){
    queryReseller(accessId: $accessId) {
      ... on ResellerAdminList {
        __typename
        data {
          id
          accessId
          name
        }
      }
    }
  }
`