import React, { useEffect } from 'react'


type Props = {
  message: React.ReactNode | string,
  content?: string,
  showCloseIcon?: Boolean,
  type: String,
  showAlert: Boolean,
  onHideAlert: () => void,
  styles?: String,
}

const Alert = ({message, onHideAlert, showAlert, showCloseIcon, type, styles}: Props) => {

  useEffect(() => {
    if(showAlert && !showCloseIcon) {
      setTimeout(() => onHideAlert(), 3000)
    }
  }, [showAlert])

  const classNames = {
    success: "bg-green-300",
    error: "bg-red-600 flex justify-between"
  }

  return <div className={`p-20 text-2lg font-base-light text-white animate-opacity fixed top-0 text-center z-101 ${classNames[type]} ${showAlert ? "opacity-1" : "opacity-0"} ${styles || "w-btn-xl"}`}>
    {message}
    {showCloseIcon && (
       <div
        className="hide-alert relative w-6 h-6 cursor-pointer"
        onClick={() => onHideAlert()}
     />
    )}
  </div>
}

export default Alert