export const roles = [
  {
    value: "admin",
    name: "Admin",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    value: "user",
    name: "User",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    value: "seller",
    name: "Seller",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    value: "assistant",
    name: "Assistant",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
];

export const formNames = [
  {
    id: "new_user",
    label: "Add new user",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    id: "edit_user",
    label: "Edit user",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    id: "delete_user",
    label: "Delete user",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    id: "add_product",
    label: "Add product",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    id: "edit_product",
    label: "Edit product",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    id: "delete_product",
    label: "Delete product",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    id: "checkout",
    label: "Checkout",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
  {
    id: "refund",
    label: "Refund",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
];

export const existingForms = [
  {
    role: "user",
    name: "Refund",
    nameId: "refund",
    id: "user_refund",
  },
  {
    role: "admin",
    name: "Add new user",
    nameId: "new_user",
    id: "admin_new_user",
  },
  {
    role: "admin",
    name: "Delete user",
    nameId: "delete_user",
    id: "admin_delete_user",
  },
  {
    role: "admin",
    name: "Edit user",
    nameId: "edit_user",
    id: "admin_edit_user",
  },
  {
    role: "seller",
    name: "Add product",
    nameId: "add_product",
    id: "seller_add_product",
  },
  {
    role: "seller",
    name: "Delete product",
    nameId: "delete_product",
    id: "seller_delete_product",
  },
  {
    role: "seller",
    name: "Edit product",
    nameId: "edit_product",
    id: "seller_edit_product",
  },
  {
    role: "seller",
    name: "Checkout",
    nameId: "checkout",
    id: "seller_checkout",
  },
  {
    role: "user",
    name: "Checkout",
    nameId: "checkout",
    id: "user_checkout",
  },
];

export const entity_types = {
  P: "Product",
  O: "Offer",
};

export const resellerProducts = [
  { name: "Monthly", value: 1 },
  { name: "2-Monthly", value: 2 },
  { name: "3-Monthly", value: 3 },
  { name: "6-Monthly", value: 6 },
  { name: "Annual", value: 12 },
];

export const activationCodeProducts = [
  { name: "activation-code-1m", value: "activation-code-1m" },
  { name: "activation-code-2m", value: "activation-code-2m" },
  { name: "activation-code-3m", value: "activation-code-3m" },
  { name: "activation-code-6m", value: "activation-code-6m" },
  { name: "activation-code-12m", value: "activation-code-12m" },
  { name: "activation-code-18m", value: "activation-code-18m" },
  { name: "lmod-reseller-monthly", value: "lmod-reseller-monthly" },
];

export const MAX_DURATION_DAYS = 1000;

export const MAX_NUMBER_OF_CODES = 50000;
