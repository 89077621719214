// @ts-nocheck
import React, { Component } from "react"
import "./index.css"

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import DisableCaptcha from "./pages/DisableRecaptcha"

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/admin" />
          </Route>
          <Route path="/admin" component={Dashboard} />
          <Route path="/login" component={Login} />
          <Route path="/plux/index/disablecaptcha" component={DisableCaptcha} />
        </Switch>
      </Router>
    )
  }
}

export default App
