export const getCountryCodes = `
  query getCountryCodes($form: FormInput!){
    selectionItemListPromotion(form: $form) {
      ... on SelectionItemList {
        __typename
        items {
          id
          value
        }
      }  
    }
  }
`