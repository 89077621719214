import React from "react"

import Textbox from "../Textbox"
import Spinner from "../LoadingIndicator/Spinner"

interface Props {
  inline?: boolean,
  isExpanded?: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>,
  onSelect: (option: any) => void | Promise<void>,
  toggleDropdown: (flag: boolean) => void | Promise<void>,
  results?: Array<any>,
  error?: string,
  label?: string,
  notFoundLabel?: string,
  keySearch?: string,
  setErrors?: () => void,
  selectedOption?: any,
  handleOnFocus?: () => void,
  renderListItem: (item: any) => JSX.Element,
  showNotFoundLabel?: boolean,
  disabled?: boolean,
  handleOnBlur?: () => void,
  loading: boolean,
}

const Autocomplete = ({
  isExpanded = false,
  onChange,
  onSelect,
  error = "",
  label = "",
  notFoundLabel = "",
  results = [],
  toggleDropdown,
  keySearch = "",
  // setErrors,
  selectedOption,
  handleOnFocus,
  renderListItem,
  showNotFoundLabel,
  disabled,
  handleOnBlur,
  loading,
}: Props) => {
  const accordionRef = React.useRef<HTMLDivElement>(null)
  const [activeOption, setActiveOption] = React.useState<number>(0)

  React.useEffect(() => {
    const handleOutsideClick = (event: TouchEvent | MouseEvent) => {
      const mouseElement = event.target as Node
      if (accordionRef.current && accordionRef.current.contains(mouseElement)) {
        if(results.length) {
          toggleDropdown(true)
        }
        return
      }
      // if (!selectedOption.id) {
      //   setErrors()
      // }
      toggleDropdown(false)
    }
    document.addEventListener("mousedown", handleOutsideClick, false)
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick, false)
    }
  }, [results, toggleDropdown, selectedOption])

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      onSelect(results[activeOption])
    } else if(e.keyCode === 40) { // Press down arrow
      if (activeOption === results.length - 1) {
        return
      }
      setActiveOption(activeOption + 1)
    } else if (e.keyCode === 38) { // Press up arrow
      if (activeOption === 0) {
        return
      }
      setActiveOption(activeOption - 1)
    }
  }

  return (
    <div
      ref={accordionRef}
      className="mt-4 relative pb-5 w-textbox-lg"
    >
      <Textbox
        disabled={disabled}
        label={label}
        classNames={{
          wrapper: "textbox-wrapper mb-32",
          textbox: "h-textbox-lg truncate w-textbox-lg pl-18",
          label: "text-2lg",
        }}
        dataCy="full-address"
        handleOnChange={e => {
          setActiveOption(0)
          onChange(e)
        }}
        id="full-address"
        error={error}
        value={keySearch}
        handleOnKeyDown={handleOnKeyDown}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
        placeholder="please enter to search "
      />
      {loading && <Spinner />}

      {!loading && isExpanded && keySearch && !results.length && showNotFoundLabel ? (
        <div
          className="absolute top-50 z-100 border-r border-l border-b border-gray-500 text-gray-400 font-base-light text-3xs py-15 pl-10 hover:bg-gray-100 cursor-pointer w-full bg-white"
        >
          <span>{notFoundLabel}</span>
        </div>):
        null
      }
      {isExpanded && results.length ? (
        <ul
          className={`cursor-pointer border-r border-l border-b border-gray-500 w-full absolute top-50 bg-white z-100 max-h-270 overflow-auto`}
        >
          {results.map((item: any, index) => {
            return (
              <li
                key={item.id}
                onClick={(e) => {
                  e.preventDefault()
                  onSelect(item)
                  setActiveOption(0)
                }}
                onMouseOver={(e) => {
                  e.preventDefault()
                  setActiveOption(index)
                }}
                className={`py-15 pl-10 ${activeOption === index ? " bg-gray-100" : ""}`}
              >
                {renderListItem(item)}
              </li>
            )
          })}
        </ul>
      ) : null}
    </div>
  )
}

export default Autocomplete