import Amplify, { Auth, graphqlOperation, API } from "aws-amplify"

import moment from 'moment'

export const splitString = (label = "", character = "&") =>
  label.replace(",", character).split(character)

export const filterArrayOptions = (options: Array<Option>, selectedOptions: Array<Option>) => {
  let CollectSelected: any = {}

  selectedOptions.forEach(item => CollectSelected[item.id] = item)

  return options ? options.filter(item => !CollectSelected[item.id]) : []
}

export const cognitoConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_AUTH_FLOW_TYPE,
  },
}

export const awsconfig = {
  aws_project_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
}

export const client = () =>
  Amplify.configure({
    ...awsconfig,
    API: {
      graphql_headers: async () => ({
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      }),
      graphql_endpoint_iam_region: process.env.REACT_APP_AWS_COGNITO_REGION,
      graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    },
  })

export const publicAPI = async (
  query: string,
  callback: (res: any) => {},
  handleError = (err: any) => {},
  variables = {},
  handleUnauthorized = () => {},
) => {
  client()

  try {
    const res = await API.graphql(
      graphqlOperation(query, variables)
    )
    callback(res)
  } catch (err) {
    err = err.response && err.response.data && err.response.data.errors || []
    if(err[0] && err[0].errorType  === 'UnauthorizedException') {
      handleUnauthorized()
    }

    // There are 2 cases for this catching:
    // - Error during executing any internal logic (there is no errors array there)
    // - Error throws from api (includes errors)
    handleError(err.errors && err.errors.length > 0 ? err.errors[0] : err)
  }
}


export const formatPromotion = (promotion) => {
  return Object.keys(promotion).map(key => ({
    items: [{
      value: promotion[key]
    }],
    name: key
  }))
}

export const debounce = (callback: any, waitFor: number) => {
  let timeout = 0
  return (...args: Parameters<any>): ReturnType<any> => {
    let result: any
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      result = callback(...args)
    }, waitFor)
    return result
  }
}

export const getCookies = (name) => {
  let pairs = document.cookie.split(";")
  const selectedCookie = {}
  pairs.forEach(str => {
    str = str.split('=')

    // get name of cookie item
    let key = str[0].split(".")
    const keyLen = key.length
    key = str[0].split(".")[keyLen - 1]

    selectedCookie[key] = str[1]
  })

  return selectedCookie[name]
}

export const AUTOMATION_RECAPTCHA = "automation-recaptcha"

export const setLocalStorage = (key = AUTOMATION_RECAPTCHA, data = {}) => {
  window.localStorage &&
    window.localStorage.setItem(key, JSON.stringify(data))
}

export const getLocalStorage = (key = AUTOMATION_RECAPTCHA) => {
  return window.localStorage
    ? JSON.parse(window.localStorage.getItem(key)) || {}
    : {}
}

export const getParamsFromURL = paramName => {
  // refer: https://stackoverflow.com/questions/1403888/get-escaped-url-parameter/8764051#8764051
  var match = RegExp("[?&]" + paramName + "=([^&;]+?)(&|#|;|$)").exec(
    window.location.href
  )
  /*
    * The encodeURIComponent() function encodes a URI component.
    * It encodes the following characters: , / ? : @ & = + $ #
    */
  return match && decodeURIComponent(match[1].replace(/\+/g, "%20"))
}

export const clearLocalStorage = () => {
  window.localStorage && window.localStorage.clear()
}

export const checkIsAdmin = (user) => {
  const payload = user.idToken && user.idToken.payload
  const cognitoGroups = payload["cognito:groups"]
  return cognitoGroups.includes("LMOD_Support_Users")
}

export const isExpiredPromotion = ({ end_date } = {}) => {
  const today = moment()
  end_date= moment(end_date)

  return end_date.isBefore(today)
}

export const timeToUTC0 = time => {
  return moment(time).toISOString()
}

export const convertCentsToEuro = (
  cents = 0,
) => {
  return (cents / 100).toFixed(2)
}

export const isFloatWith2Decimal = (num) => {
  const pattern = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/

  return pattern.test(num)
}

export const isInteger = num => {
  const pattern = /^\d+$/

  return pattern.test(num)
}

export const formatPricesTable = data => {
  return data.map(item => {
      const { countryCode, taxIncluded, currency, entity, bauPriceInCents, promotionPriceInCents, discountAmount, discountPercentage, trialDays} = item
      return {
        countryCode: countryCode.toUpperCase(),
        entity,
        currency,
        taxIncluded,
        bauPriceInCents: bauPriceInCents ? convertCentsToEuro(bauPriceInCents) : "",
        promotionPriceInCents: {
          value: promotionPriceInCents ? convertCentsToEuro(promotionPriceInCents) : ""
        },
        ...discountAmount && {
          discountAmount: convertCentsToEuro(discountAmount)
        },
        ...discountPercentage && {
          discountPercentage: discountPercentage
        },
        trialDays,
      }
  })
}

export const getLastEnrolmentDate = () => {
  let date = new Date()
  date.setDate(date.getDate() + 90)
  return date
}

export const getDurationDays = (startDate, endDate) => {
  startDate = new Date(startDate)
  endDate = new Date(endDate)
  const diffTime = Math.abs(startDate - endDate)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}